<template>
  <div class="content">
    <div id="search_top" class="search-top">
      <div class="input-group-inline right">
        <Button
          type="primary"
          @click="dataQuery"
          size="default"
          style="width:60px;height: 36px;"
          >查询</Button
        >
        <Button
          @click="clear"
          style="margin-left:16px;width:60px; height: 36px;"
          size="default"
          >重置</Button
        >
      </div>

      <div class="input-group-inline" style="margin-right: 16px">
        <label>运单号：</label>
        <Input
          class="control-width"
          v-model="queryData.shippingNoteNumber"
          placeholder="请输入运单号"
          size="default"
        ></Input>
      </div>
      <div class="input-group-inline" style="margin-right: 16px">
        <label>设备号：</label>
        <Input
          class="control-width"
          v-model="queryData.device"
          placeholder="请输入设备号"
          size="default"
        ></Input>
      </div>
      <!-- <div class="input-group-inline" style="margin-right: 16px">
        <label>车牌号：</label>
        <Input
          class="control-width"
          v-model="queryData.vehicleNumber"
          placeholder="请输入车牌号"
          size="default"
        ></Input>
      </div> -->
      <div class="input-group-inline" style="margin-right: 16px">
        <label>运单状态：</label>
        <Select
          multiple
          class="custom-control-width"
          v-model="queryData.statusList"
          style="width: 360px"
          :max-tag-count="3"
          size="default"
        >
          <Option
            v-for="items in transportBillStatusList"
            :value="items.value"
            :key="items.label"
            >{{ items.label }}</Option
          >
        </Select>
      </div>
      <div class="input-group-inline">
        <label>运单接收：</label>
        <Select
          class="custom-control-width"
          v-model="queryData.sendStatus"
          style="width: 160px"
          size="default"
        >
          <!-- <Option :value="null">全部</Option> -->
          <Option :value="1">成功</Option>
          <Option :value="2">失败</Option>
        </Select>
      </div>
    </div>

    <Table
      id="table"
      highlight-row
      stripe
      style="overflow: visible;"
      ref="table"
      size="default"
      :loading="loading"
      :height="tableHeight"
      :columns="columns"
      :data="tableData"
    ></Table>
    <div id="page-box" class="page-box">
      <Button type="primary" @click="goToTroubleShoot" size="default"
        >问题排查</Button
      >
      <Button
        type="primary"
        style="margin-left:16px;"
        @click="batchRetry($refs.table.getSelection())"
        size="default"
        >批量重推</Button
      >
      <Page
        :total="total"
        :current="current"
        size="small"
        :page-size="pageSize"
        show-total
        show-sizer
        :page-size-opts="[50, 100, 150, 200]"
        @on-change="changePage"
        @on-page-size-change="changePageSize"
      ></Page>
    </div>
    <Modal
      v-model="deleteConfirm"
      class="vertical-center-modal"
      :closable="false"
      :mask-closable="false"
      width="300"
      :footer-hide="true"
    >
      <div class="modal-cont">
        <div class="modal-title">
          <Icon type="ios-information-circle"></Icon>提示
        </div>
        <p class="modal-desc">是否确定删除该运单？</p>
        <div class="modal-button">
          <Button
            @click="handleCancel"
            size="default"
            style="margin-right: 12px;"
            >取消</Button
          >
          <Button
            type="primary"
            @click="handleDelete"
            size="default"
            :loading="loadingBtn"
            style="margin-left: 12px;"
            >确定</Button
          >
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ajax, getUrlParam } from '../../libs/util'
import tableHelper from '../../components/mixins/table-helper.vue'
import deviceMix from '../../components/mixins/device-mix'
import Cookies from 'js-cookie'
import { transportBillStatusList } from './constants'
import fenceInfo from './fenceInfo.vue'
import sendStatusInfo from './sendStatusInfo.vue'
export default {
  mixins: [tableHelper, deviceMix],
  data() {
    let queryData = {
      shippingNoteNumber: '',
      device: '',
      vehicleNumber: '',
      sendStatus: null,
      statusList: [],
    }

    return {
      deleteId: null,
      deleteConfirm: false,
      loadingBtn: false,
      tokenFromUrl: getUrlParam('token'),
      companyJson: [],
      queryData: queryData,
      isAll: false,
      current: 1,
      pageSize: 50,
      tableHeight: '',
      total: 0,
      tableData: [],
      deviceModeList: [],
      loading: false,
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          type: 'index',
          title: '序号',
          width: 70,
          align: 'center',
        },
        {
          title: '运单号',
          minWidth: 200,
          key: 'shippingNoteNumber',
          render: (h, params) => {
            return h(
              'span',
              {
                style: {
                  fontSize: '14px',
                  color: '#0076ff',
                  cursor: 'pointer',
                },
                on: {
                  click: () => {
                    this.$router.push({
                      path: '/map/transport_bill_track',
                      query: {
                        shippingNoteNumber: params.row.shippingNoteNumber,
                      },
                    })
                  },
                },
              },
              params.row.shippingNoteNumber
            )
          },
        },
        {
          title: '所属线路',
          minWidth: 200,
          key: 'routerNumber',
          render: (h, params) => {
            return params.row.fenceInfoList && params.row.fenceInfoList.length
              ? h(fenceInfo, {
                  props: {
                    fenceInfo: params.row.fenceInfoList,
                    status: params.row.status,
                  },
                })
              : h('span', '')
          },
        },
        {
          title: '运单状态',
          minWidth: 200,
          key: 'statusName',
        },
        // {
        //   title: '车辆',
        //   minWidth: 200,
        //   key: 'vehicleNumber',
        // },
        {
          title: '设备号',
          minWidth: 200,
          key: 'device',
        },
        {
          title: '装货围栏触发时间',
          minWidth: 200,
          key: 'loadTime',
          render: (h, params) => {
            const loadData = !!params.row.fenceInfoList
              ? params.row.fenceInfoList.filter(
                  (v) => v.type === 1 && v.triggerStatus === 1
                )
              : null
            return h(
              'span',
              (loadData && loadData[0] && loadData[0].triggerTimeStr) || ''
            )
          },
        },
        {
          title: '卸货围栏触发时间',
          minWidth: 200,
          key: 'unloadTime',
          render: (h, params) => {
            const unloadData = !!params.row.fenceInfoList
              ? params.row.fenceInfoList.filter(
                  (v) => v.type === 3 && v.triggerStatus === 1
                )
              : null
            return h(
              'span',
              (unloadData && unloadData[0] && unloadData[0].triggerTimeStr) ||
                ''
            )
          },
        },
        {
          title: '运单接收',
          minWidth: 150,
          key: 'sendStatusName',
          render: (h, params) => {
            return h(sendStatusInfo, {
              props: {
                row: params.row,
              },
            })
          },
        },
        {
          title: '操作',
          key: 'action',
          width: 120,
          render: (h, params) => {
            return h('div', [
              params.row.sendStatus === 2 &&
                h(
                  'span',
                  {
                    style: {
                      fontSize: '14px',
                      color: '#0076ff',
                      cursor: 'pointer',
                      marginRight: '16px',
                    },
                    on: {
                      click: () => {
                        const rId =
                          params.row.fenceInfoList &&
                          params.row.fenceInfoList[0].shipId
                        if (!rId) {
                          this.$Message.error('运单数据有误')
                          return
                        }
                        this.handleRetry(rId)
                      },
                    },
                  },
                  '重推'
                ),
              h(
                'span',
                {
                  style: {
                    fontSize: '14px',
                    color: '#0076ff',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteId = params.row.id
                      if (!this.deleteId) {
                        this.$Message.error('运单数据有误')
                        return
                      }
                      this.deleteConfirm = true
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      addLoading: false,
      transportBillStatusList,
    }
  },
  methods: {
    dataQuery() {
      this.query()
    },
    query(page = 1) {
      this.loading = true
      this.tableData = []
      let reqData = this.queryData
      reqData.page = page
      reqData.pageSize = this.pageSize
      ajax.post(
        '/shipping/shipping_note_list',
        reqData,
        (res) => {
          //客户维度搜索
          if (this.queryData.uid) {
            delete this.queryData.uid
          }
          //订单维度搜索
          if (this.queryData.oid) {
            delete this.queryData.oid
          }
          if (res.code === 0) {
            this.tableData = res.data ? res.data.data || [] : []
            this.tableData.forEach((item) => {
              item._disabled = item.sendStatus !== 2
            })
            this.total = res.data ? res.data.total : 0
            this.isAll = false
          }
          this.loading = false
        },
        (err) => {
          this.loading = false
          this.$Message.error(err.message || err.msg || '服务器异常，请重试')
        }
      )
    },
    getDeviceModeList() {
      ajax.post(
        '/device/get_product_model_list',
        {},
        (res) => {
          this.loading = false
          if (res.code === 0) {
            //为了使用TreeSelect 的数据格式
            this.deviceModeList = [
              {
                id: '',
                label: '全部',
                isDefaultExpanded: true,
                children: _.sortBy(res.data, ['name']).map((item) => ({
                  id: item.id,
                  label: item.productModel,
                })),
              },
            ]
          } else {
            this.deviceModeList = []
          }
        },
        (err) => {
          this.deviceModeList = []
          this.$Message.error(err.message || '服务器异常，请重试')
        }
      )
    },
    getCompanyList() {
      ajax.post(
        '/customer/list',
        {
          page: 1,
          pageSize: 999,
        },
        (res) => {
          this.loading = false
          if (res.code === 0) {
            //为了使用TreeSelect 的数据格式
            this.companyJson = res.data.data
          } else {
            this.companyJson = []
          }
        },
        (err) => {
          this.companyJson = []
          this.$Message.error(err.message || '服务器异常，请重试')
        }
      )
    },
    clear() {
      this.queryData = {
        shippingNoteNumber: '',
        device: '',
        sendStatus: null,
        vehicleNumber: '',
        statusList: [],
      }
    },
    changePage(page) {
      this.query(page)
    },
    changePageSize(size) {
      this.pageSize = size
      this.query(1)
    },
    resetFields(value) {
      // 重置数据
      this.showDeviceDtlModal = false
      this.showRateModal = false
      this.addLoading = false
      this.$refs[value].resetFields()
    },
    landLogin() {
      ajax.post(
        '/user/token_login',
        {
          token: decodeURIComponent(this.tokenFromUrl),
        },
        (res) => {
          Cookies.set('token', res.data.token, { path: '/' })
          Cookies.set('name', res.data.userinfo.name, { path: '/' })
          this.initData()
        },
        (err) => {
          this.$Message.error(err.msg || '服务器异常，请重试')
        }
      )
    },
    initData() {
      // this.getDeviceModeList()
      // this.getCompanyList()
      this.query()
    },
    goToTroubleShoot() {
      this.$router.push({ path: '/map/trouble_shoot' })
    },
    handleDelete() {
      this.loadingBtn = true
      ajax.post(
        '/shipping/del',
        { id: this.deleteId },
        (res) => {
          this.loadingBtn = false
          this.$Message.success('删除成功')
          this.dataQuery()
          this.deleteConfirm = false
        },
        (err) => {
          this.loadingBtn = false
          this.$Message.error(err.message || '服务器异常，请重试')
        }
      )
    },
    handleRetry(rid) {
      this.$Spin.show()
      ajax.post(
        '/shipping/push',
        { id: rid },
        (res) => {
          setTimeout(() => {
            this.$Spin.hide()
            this.$Message.success('重推成功')
            this.dataQuery()
          }, 1000)
        },
        (err) => {
          this.loadingBtn = false
          this.$Message.error(err.message || '服务器异常，请重试')
        }
      )
    },
    batchRetry(selectList) {
      if (selectList.length > 50) {
        this.$Message.error('一次最多推送50条运单')
        return
      }
      if (!selectList.length) {
        this.$Message.error('请先勾选运单')
        return
      }
      this.$Spin.show()
      let idList = []
      selectList.forEach((item) => {
        if (item.fenceInfoList && item.fenceInfoList[0])
          idList.push(item.fenceInfoList[0].shipId)
      })
      ajax.post(
        '/shipping/batch_push',
        { ids: idList.join(',') },
        (res) => {
          setTimeout(() => {
            this.$Spin.hide()
            this.$Message.success('推送操作已完成')
            this.dataQuery()
          }, 1000)
        },
        (err) => {
          this.loadingBtn = false
          this.$Message.error(err.message || '服务器异常，请重试')
        }
      )
    },
    handleCancel() {
      this.deleteId = null
      this.deleteConfirm = false
    },
  },
  created() {
    if (this.tokenFromUrl) {
      this.landLogin()
    } else {
      this.initData()
    }
  },
  beforeRouteUpdate() {
    this.clear()
    this.query()
  },
}
</script>

<style lang="less">
.search-top {
  input {
    height: 36px !important;
  }
  .ivu-select-default.ivu-select-multiple .ivu-select-selection {
    min-height: 36px;
    height: 36px;
  }
}
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 40%;
  }
}
.deviceLabel {
  margin-left: 10px;
  font: bold 14px Georgia;
}

.line {
  background-color: #e5e5e5;
  height: 1px;
  margin-top: 5px;
}

.pop-width {
  width: 200px;
}

.col-label {
  display: inline-block;
}

.pop {
  .ivu-row,
  .ivu-row-flex {
    margin-bottom: 20px;
  }
}
.content {
  padding: 0;
}
.modal-cont {
  .modal-title {
    font-size: 16px;
    text-align: center;
    i {
      color: #ff7e33;
      font-size: 18px;
      margin-right: 8px;
    }
  }
  .modal-desc {
    color: #5e5e5e;
    text-align: center;
    margin-top: 16px;
  }
  .modal-button {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
</style>
