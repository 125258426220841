var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Tooltip", { attrs: { placement: "top" } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.totalRouteData.length > 1 && _vm.status !== 2
                ? _vm.totalRouteData.length
                : _vm.route
            ) +
            " "
        ),
        _c(
          "div",
          { attrs: { slot: "content" }, slot: "content" },
          _vm._l(_vm.totalRouteData, function(item, idx) {
            return _c(
              "div",
              { key: idx, staticStyle: { "white-space": "normal" } },
              [
                _c("div", [
                  _vm._v(_vm._s(idx + 1) + ". " + _vm._s(item.unload.route))
                ]),
                _c("div", [_vm._v("装：" + _vm._s(item.load.address))]),
                _c("div", [_vm._v("卸：" + _vm._s(item.unload.address))])
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }