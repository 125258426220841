<template>
  <div>
    <span v-if="row.sendStatus === 2" style="color:red;">{{ row.sendStatusName }}</span>
    <span v-else>{{ row.sendStatusName }}</span>
    <Tooltip placement="top" v-if="row.sendStatus === 2" style="margin-left:4px;">
      <Icon type="ios-help-circle-outline" />
      <div slot="content">
        <div style="white-space: normal">
          {{
            row.sendMessage
          }}
        </div>
        
      </div>
    </Tooltip>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
}
</script>
