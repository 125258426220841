var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.row.sendStatus === 2
        ? _c("span", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.row.sendStatusName))
          ])
        : _c("span", [_vm._v(_vm._s(_vm.row.sendStatusName))]),
      _vm.row.sendStatus === 2
        ? _c(
            "Tooltip",
            {
              staticStyle: { "margin-left": "4px" },
              attrs: { placement: "top" }
            },
            [
              _c("Icon", { attrs: { type: "ios-help-circle-outline" } }),
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { staticStyle: { "white-space": "normal" } }, [
                  _vm._v(" " + _vm._s(_vm.row.sendMessage) + " ")
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }