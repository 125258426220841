/*
 *list 转化成map表
 *value:label
 */
export const listToMap = (list, keyName = 'value', labelName = 'label') => {
  return list.reduce((res, cur) => {
    res[cur[keyName]] = cur[labelName]
    return res
  }, {})
}

export const transportBillStatusList = [
  { value: 0, label: '待触发' },
  { value: 1, label: '进行中' },
  { value: 2, label: '触发结束' },
  { value: 3, label: '非触发结束' },
  { value: 4, label: '手动终止' },
  
]

export const transportBillStatusMap = listToMap(transportBillStatusList)
