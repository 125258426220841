<template>
  <div>
    <Tooltip placement="top">
      {{
        totalRouteData.length > 1 && status !== 2
          ? totalRouteData.length
          : route
      }}
      <div slot="content">
        <div
          v-for="(item, idx) in totalRouteData"
          :key="idx"
          style="white-space: normal"
        >
          <div>{{ idx + 1 }}. {{ item.unload.route }}</div>
          <div>装：{{ item.load.address }}</div>
          <div>卸：{{ item.unload.address }}</div>
        </div>
      </div>
    </Tooltip>
  </div>
</template>

<script>
export default {
  name: 'fenceInfo',
  props: {
    fenceInfo: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
    },
  },
  data(props) {
    let loadInfo = null
    let totalRouteData = []
    let route = ''
    let isTriggered = false
    // 线路取值逻辑
    // 先找运单有无【已触发】状态的【卸货围栏】：
    // 有，取卸货围栏对应的线路；
    // 无：卸货围栏=1，展示这个围栏对应的线路;卸货围栏＞1，展示所有卸货围栏对应的所有线路
    this.fenceInfo.forEach((item) => {
      if(item.type === 3 && item.triggerStatus == 1) {
        isTriggered = true
      }
    })
    this.fenceInfo.forEach((item) => {
      if(isTriggered) {
        if (item.type === 3 && item.triggerStatus === 1) {//如果是卸货围栏
          route = item.route
        }
      }else {
        if (item.type === 3 && item.triggerStatus !== 2) {//如果是卸货围栏
          route = item.route
        }
      }
      
      if (item.type === 1) {//装货
        loadInfo = item
      } else if (item.type === 3) {//卸货
        if(isTriggered) {
          if(item.triggerStatus === 1) {
            totalRouteData.push({
              load: loadInfo,
              unload: item,
            })
          }
        }else {
          if(item.triggerStatus !== 2) {
            totalRouteData.push({
              load: loadInfo,
              unload: item,
            })
          }
        }
      }
    })
    
    return {
      route,
      totalRouteData
    }
  },
  created() {
    // this.fenceInfo.forEach((item) => {
      
    // })
  },
}
</script>
