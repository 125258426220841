<script>
import { getTableHeight } from '../../libs/util'

export default {
  data() {
    return {
      tableHeight: 1000, // 默认高1000
    }
  },
  mounted() {
    setTimeout(() => {
      this.tableHeight = getTableHeight()
    }, 10)
  },
  methods: {
    renderGear(h, ...buttons) {
      return h('div', [
        h('Poptip', { props: { trigger: 'hover', placement: 'right' } }, [
          h('Icon', { props: { type: 'md-settings', size: '20' } }),
          h('div', { slot: 'content' }, [
            h('ButtonGroup', { props: { vertical: true } }, [...buttons]),
          ]),
        ]),
      ])
    },
  },
}
</script>
